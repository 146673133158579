.counter{
    display: flex;
    align-items: center;
    justify-content: center;
    background-image:linear-gradient(rgba(0, 0, 0, 0.59), rgba(0, 0, 0, 0.59)),  url('/public/1.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: auto;
}

.out-box {   
    display: flex;
    flex-direction: column;
    width: 65%;
    margin:40px 0 60px 0;
    padding: 20px 0;
    border-radius: 20px;
    min-height: 80vh;
    height: fit-content;
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
}

.End {
    text-align: center;
    padding: 20vh 0px;
}

.Barra2{
    display: flex;
    justify-content: center;
}
.Barra {
    display: flex;
    justify-content: center;
    width: 450px;
    align-items: center;

}
.Barra-button {
    background-color: #18509e !important;
    font-weight: bold !important;
    width: auto !important;
}
.Barra-button:hover {
    background-color: white !important;
    color: #18509e !important;
    width: auto !important;
    font-weight: bold;
    border: 1px solid #18509e;
}
.titulo_bold{
    font-weight: bold;
    font-size: 30px;
}
.center-text {
    text-align: center;
    text-align: -webkit-center;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    font-size: 27px;
}
.center-align {vertical-align: middle;}
.danger-text {color: crimson;}
.warning-text {color: yellow;}
.link-text {text-decoration: underline; color: #fafafa; cursor: pointer;}
.image-active {
    position: relative;    
    width: 9vw;    
    min-width: 8vw;
    aspect-ratio: 1/1;
    align-self: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
 }

.active-footer {
    position: fixed !important;
    bottom: 0px !important;
}
.Corner-button {
    position: absolute;
    left: 20px;
    top: 5vh;
    min-width: 60px;
    width: auto; 
}


/* modificacion de diseño respomsivo de las encuestas */
/* ======================================= */

.nps-grid {
    display: flex;
    grid-template-rows: auto auto;
    gap: 5px;
}
/* === Diseño para Tablet === */

@media (min-width: 768px) and (max-width: 1023px) {
    .nps-grid {
        display: flex;
        grid-template-rows: auto auto;
        justify-content: center;
        gap: 5px;
    }
    .counter{
        padding: 40px;
    }
    .out-box{
        width: 100%;
        margin: 10px 0 20px;
    }
    .titulo_bold{
        font-weight: bold;
        font-size: 25px;
    }
    .nps-item{
        width: 45px;
    }
    .nps-item2{
        padding: 0 5px;
    }
    .smiley{
        padding: 0 0 0 6px;
    }
}

/* === Diseño para celulares === */

@media (max-width: 767px) {
    .nps-grid {
        display: grid;
        gap: 5px;
        width: 100%;
        max-width: 400px;
        margin: 0 0 60px;
    }
    .counter{
        padding: 0 20px 60px;
    }
    .out-box{
        width: 100%;
    }
    .titulo_bold{
        font-weight: bold;
        font-size: 20px;
    }
    .Barra{
        padding: 2px;
        width: 340px;
    }
    .center-text{
        display: flex;
        justify-content: center;
        padding: 0% !important;
        font-size: 17px;
    }
    .nps-item {
        display: flex;
        width: 100%;
    }
}