.side-link {
    text-decoration: none;
    color: black;
}
/* ajusta el ancho del encabezado */
.header {
    width: 100% !important;
    display: flex;
    position: relative;
    padding: 0%;
    margin: 0%;
}
/* cambios n0o reflejados aun  */
.sidebar li:hover, .active{
    background-color: rgba(211, 211, 211, 0.3);
    color: #333;
}
/* alto del contedor  */
.content {
    min-height: 100vh;
    height: 100%;
    position: relative;
}
