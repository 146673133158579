/* contenedor de selecci0on de las empresas  */
.inicio-container {
    /* background-color: #f1f1f1; */
    min-height: 82vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0;
    padding: 20px;
}
/* posicion de nombre de la empresa de seleccion de un cliente */
.Sub-title {
    position: relative;
    top: 12vh;
}
.tit_panel{
    /* background: linear-gradient(45deg, #0c4a6e, #7798ab, #ffcc00); */ 
    background: linear-gradient(45deg, #0d9afe, #ff38e4); /* 3 colores */
    -webkit-background-clip: text; /* Aplica el degradado al texto */
    -webkit-text-fill-color: transparent; /* Hace el texto transparente para mostrar el fondo */
    display: inline-block;
    font-size: 35px;
    font-weight: bold;
}

/* diseño para el contenedor de seleccion de  clientes  */
.Gallery {
    background-color: #FFF;
    min-height: 60vh;
    width: 80vw;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 10px 5px #ECEBF9;
} 
/* flex de la galeria  */
.gallery-flex {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: inherit;
}
.gallery-item, .gallery-item-active {
    margin: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    min-height: 100px;
    width: 10vw;
    height: 10vw;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 2px #ECEBF9;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.gallery-item-active {
    border: solid thin #0c4a6e ;
    font-weight: bold;
}

.gallery-item-secondary {
    display: flex;
    margin: 0.5rem;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 2px #ECEBF9;
    align-items: center;
    min-width: 100px;
    min-height: 50px;
    width: 10vw !important;
}

.floating-edit {
    position: relative;
    top: 5%;
    left: 96%;
    font-size: large;
    cursor: pointer;
}
.client-view {
    width: inherit !important;
    padding: 1rem;
}
/* aun no se save lo que hace, no hay cambios */
.data-client {
    display: flex;
    flex-direction: column;
}
.data-card {
    display: flex;
    justify-content: flex-end;
}
.data-item {
    display: flex;
    flex-direction: column;
}
/* no genera cambios, falta identificar */
.graph-container {
    display: flex;
    justify-content: space-around;
    padding: 1rem;
    flex-wrap: wrap;
}

.graph-1-2 {
    background-color: #FFF;
    padding: 1rem;
    width: 49%;
    height: 60vh;
    border-radius: 5px;
}
.graph-1-1 {
    background-color: #FFF;
    padding: 1rem;
    width: inherit;
    height: 60vh;
    border-radius: 5px;
}
.graph-1-3 {
    background-color: #FFF;
    padding: 1rem;
    width: 30vw;
    height: 40vh;
    border-radius: 5px;
}
.graph-card {
    text-align: center;
    position: absolute !important;
    width: min-content;
    z-index: 3;
}
.main-graph {
    position: relative;
    height: inherit;
    left: 0vw;
}

.graph-card-title {
    padding: 1rem !important;
    background-color: green;
    color: #FFF;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px ;
}
.graph-card-text {
    padding: .5rem .5rem !important;
}
/* cambios sin reflejar aun  */
.linemark-series {
    stroke-width: 3px;
    fill: none;
}
/* estilos para la puntuacion */

.score {
    padding: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    max-width: 70% !important;
}
.score-number {
    padding:  .9rem 1rem;
    color: #FFF;
    width: fit-content !important;
    background-color: green;
    border-radius: 5px;
}
.top-view{
    z-index: 99;
}
.graph-row {
    position: absolute;
    width: 25%;
    z-index: 3;
    justify-content: space-between !important;
    align-items: flex-start !important;
    flex-wrap: nowrap !important;
}
.button-holder-2 {
    display: flex;
    justify-content: flex-end;
}

.Top-item {
    z-index: 10;
}
.Top-item-alt {
    left: 2rem;
    top: -2rem;
    position: relative;
    font-size: 1.5rem;
    z-index: 10;
}
.text-center { 
    vertical-align: middle !important;
}
.inicio-search {
    width: 20vw !important;
}
.front-table {
    max-width: 43vw;
    max-height: 45vh;
    background-color: white;
}

/* hace que se ajuste el ancho de selector de encuestas  */
@media only screen and (min-width: 1110px) {
    .select-search {
        width: 20vw !important;
    }    
    .option-container-modal {
        width: 15vw !important;
    }
}
/* cambios no reflejados aun */
.full-width {
    max-width: inherit !important;
    width: 100% !important;
}
/* cambios no reflejados aun */
.place-image {
    border-radius: 50%;
    width: 20vw !important;
}
.graph-1-2-temp {
    background-color: #FFF;
    padding: 1rem;
    width: 60vw !important;
    height: 25vh !important;
    border-radius: 5px;
}
.floating-text {
    position: relative;
    top: 1rem;
    font-size: 1rem;
}