.Overlay {
    background-color: #FFF;
    z-index: 10;
    height: 92vh;
    left: 0vw;
    top: 0vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
}

.muted-text {
    font-size: 12px;
}

.control-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow-y: scroll;
    max-height: 130vh !important;
}

.control-text {
    padding: .3rem; 
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.text-left {
    text-align: left !important;
}

.short {
    width: 50% !important;
}

.plus-button {
    display: flex;
    background-color: #0d6efd;
    color: white;
    border-radius: 5px;
    width: 3vw !important;
    height: 3vw;
    font-size: 1.1rem;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.plus-button-alt {
    display: flex;
    background-color: lightgray;
    color: black;
    border-radius: 5px;
    width: 1.5vw !important;
    height: 1.5vw;
    font-size: .9rem;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.control-text>h6:hover {
    border-radius: 10px;
    background-color: blue;
    color: white !important;
}

.control-text>h6 {
    padding: .3rem;
    min-width: 13vw;
    text-align: left;
}

.disabled-option {
    border-radius: 10px;
    background-color: lightgray;
    color: gray !important;
}

.disabled-option:hover {
    border-radius: 10px;
    background-color: lightgray !important;
}

.form-width {
    width: 80% !important;
}

.form-footer {
    width: 80% !important;
    display: flex;
    font-size: .5rem;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
}
.pointer {
    cursor: pointer;
}

.red {
    color: red
}

.orange {
    color: #f58700;
}

.yellow {
    color: orange;
}

.green {
    color: greenyellow;
}

.deepgreen {
    color: green;
}

.gold {
    color: gold !important;
}

.gray {
    color: gray;
}

.black {
    color: #000 !important
}

.h-3 {
    height: 30vh;
}

.h-2 {
    height: 20vh;
}

.h-1 {
    height: 10vh;
}

.v-3 {
    width: 15vh;
}

.v-2 {
    width: 10vh;
}

.v-1 {
    width: 5vh;
}

.square {
    border-radius: 0px !important;
}

.option-container {
    width: 20vw !important;
}

.collumn {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.collumn-option {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.select-height {
    max-height: 90vh;
}

.select-height-min {
    max-height: 50vh;
}

.clickable:hover {
    text-decoration: underline;
    color: blue !important;
}

.style-form-field {
    border: none !important;
    border-bottom: thin solid black !important;
}

.left {
    position: relative !important;
    right: 50vw;
}

.active-step,
.rest-step {
    cursor: pointer;
    padding: 0rem 0.3rem;
    text-wrap: nowrap;
}

.active-step {
    background-color: lavender !important;
}

.active-step-enc {
    border: solid thin #0d6efd !important;
    background-color: lavender !important;
}

.form-option,
.form-option-static {
    color: black !important;
    display: flex !important;
    border: solid thin !important;
    width: fit-content !important;
    cursor: pointer !important;
    padding: .5vw !important;
    border-radius: 5px !important;
    background: aliceblue !important;
    justify-content: center;
}

.form-option-slim {
    background: aliceblue !important;
    color: black !important;
}

.form-option-slim-active {
    background: #bdb7f7 !important;
    color: black !important;
}

/* ===========================fin de la modificacion=============== */

.form-option-slim-active,
.form-option-slim-0,
.form-option-slim-1,
.form-option-slim-2,
.form-option-slim-3,
.form-option-slim-4,
.form-option-slim-5,
.form-option-slim-6,
.form-option-slim-7,
.form-option-slim-8,
.form-option-slim-9,
.form-option-slim-10 {
    align-items: center;
    justify-content: center;
    min-width: 50px;
    min-height: 50px !important;
    display: flex !important;
    border: solid thin !important;
    width: fit-content !important;
    height: fit-content;
    cursor: pointer !important;
    padding: .5vw 1rem !important;
    border-radius: 50% !important;
    font-size: 18px !important;
    font-weight: bold !important;
}

.form-option-grid {
    align-items: center;
    justify-content: center;
    min-width: 5rem;
    color: black !important;
    display: flex !important;
    border: solid thin !important;
    max-height: 10vh;
    letter-spacing: 0px;
    height: 10vh;
    max-width: 10vw !important;
    cursor: pointer !important;
    padding: .5vw .5rem !important;
    border-radius: 5px !important;
    background: aliceblue !important;
}

.form-option-grid-active {
    align-items: center;
    justify-content: center;
    min-width: 5rem;
    color: black !important;
    display: flex !important;
    border: solid thin !important;
    max-height: 10vh;
    letter-spacing: 0px;
    height: 10vh;
    max-width: 10vw !important;
    cursor: pointer !important;
    padding: .5vw .5rem !important;
    border-radius: 5px !important;
    background: #7c78a2 !important
}
.finaliza_2{
    background-color: #18509e !important;
    font-weight: bold !important;
    width: auto !important;
}
.finaliza_2:hover{
    background-color: white !important;
    color: #18509e !important;
    width: auto !important;
    font-weight: bold;
    border: 1px solid #18509e;
}
.form-option-slim{
    background: #18509e !important;
    color: white !important;
    border: 1px solid #18509e !important;
    font-weight: bold !important;
}
.form-option-slim:hover,
.form-option:hover,
.form-option-grid:hover {
    background: white !important;
    border: 1px solid #18509e !important;
    color: #18509e !important;
    font-weight: bold !important;
}
/* diseño de los iconos  */
/* ===================== */
.smiley {
    display: inline-block;
    margin: 3px;
    font-size: 40px;
    transition: transform 0.2s ease-in-out;
}
.smiley:hover{
    transform: scale(1.2);
}

.form-option-slim-0:hover {
    background-color: #f8696b !important;
}

.form-option-slim-1:hover {
    background-color: #f98370 !important;
}

.form-option-slim-2:hover {
    background-color: #fa9d75 !important;
}

.form-option-slim-3:hover {
    background-color: #fcb77a !important;
}

.form-option-slim-4:hover {
    background-color: #fdd17f !important;
}

.form-option-slim-5:hover {
    background-color: #ffeb84 !important;
}

.form-option-slim-6:hover {
    background-color: #e0e383 !important;
}

.form-option-slim-7:hover {
    background-color: #c1da81 !important;
}

.form-option-slim-8:hover {
    background-color: #a2d07f !important;
}

.form-option-slim-9:hover {
    background-color: #83c77d !important;
}

.form-option-slim-10:hover {
    background-color: #63be7b !important;
}

.form-option-slim-0 {
    color: #000 !important;
    border: solid 2px #f8696b !important;
    background: aliceblue !important;
}

.form-option-slim-1 {
    color: #000 !important;
    border: solid 2px #f98370 !important;
    background: aliceblue !important;
}

.form-option-slim-2 {
    color: black !important;
    border: solid 2px #fa9d75 !important;
    background: aliceblue !important;
}

.form-option-slim-3 {
    color: black !important;
    border: solid 2px #fcb77a !important;
    background: aliceblue !important;
}

.form-option-slim-4 {
    color: black !important;
    border: solid 2px #fdd17f !important;
    background: aliceblue !important;
}

.form-option-slim-5 {
    color: black !important;
    border: solid 2px #ffeb84 !important;
    background: aliceblue !important;
}

.form-option-slim-6 {
    color: black !important;
    border: solid 2px #e0e383 !important;
    background: aliceblue !important;
}

.form-option-slim-7 {
    color: black !important;
    border: solid 2px #c1da81 !important;
    background: aliceblue !important;
}

.form-option-slim-8 {
    color: black !important;
    border: solid 2px #a2d07f !important;
    background: aliceblue !important;
}

.form-option-slim-9 {
    color: black !important;
    border: solid 2px #83c77d !important;
    background: aliceblue !important;
}

.form-option-slim-10 {
    color: black !important;
    border: solid 2px #63be7b !important;
    background: aliceblue !important;
}

.flex {
    display: flex;
}

.form-number {
    font-size: 1.3rem;
    margin: 0px !important;
    display: flex;
    align-items: baseline;
    flex-direction: row;
}

.form-number-slim {
    font-size: 1rem;
    margin: 0px !important;
    display: flex;
    align-items: baseline;
    flex-direction: row;
}

.mt-15 {
    margin-top: 15vh !important;
}

.mb-10 {
    margin-bottom: 10vh !important;
}

.big-icon {
    font-size: 3rem !important;
}