.top-bubble {
    border-radius: 10px 10px 0px 0px;
    background-color: #DAD8F6;
    height: 1rem;
}
.bottom-bubble {
    border-radius: 0px 0px 10px 10px;
    background-color: #DAD8F6;
    height: 1rem;
}
.video-form {
    box-shadow: inset 0px 0px 5px 5px #DAD8F6;
    border-radius: 10px;
    overflow-y: scroll;
}
.video-response {
    background-color: #DAD8F6;
}

.row-excel-button {
    display:flex;
    justify-content:space-between ;
    padding:1em
}

.result-display { 
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
}
.btn-outline-warning-active {
    background-color:#ffc107 !important;
    color: black !important;
}
.btn-outline-success-active {
    background-color: #198754  !important;
    color: black !important;
}
.btn-outline-danger-active {
    background-color: #dc3545 !important;
    color: black !important;
}
.btn-active {
    background-color: #6454d6 !important;
    color: white !important;
}