.parametro-R{box-shadow: 0px 0px 5px 0px red;}
.parametro-A{box-shadow: 0px 0px 5px 0px yellow;}
.parametro-V{box-shadow: 0px 0px 5px 0px greenyellow;}
.slight-border {
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px #E5E5E5;
}
.centered {
    display: flex;
    justify-content: center !important;
    margin-bottom: -10vh;
}
.Default-Fondo {
    border-radius: 5px;
    width: inherit !important;
    padding: .5rem !important;
}
.Fondo-Blank, .Fondo-A, .Fondo-R, .Fondo-V {
    border-radius: 5px;
    width: 6rem !important;
    min-width: 6rem !important;
    max-width: 6rem !important;
    padding: .5rem !important;
    text-align: center;
    vertical-align: middle;
}
.Fondo-Blank-E, .Fondo-A-E, .Fondo-R-E, .Fondo-V-E {
    border-radius: 5px;    
    min-width: 6rem !important;    
    padding: .5rem !important;
    text-align: center;
    vertical-align: middle;
}
.Fondo-R-E,.Fondo-R{background-color:#f08080 !important ;}
.Fondo-V-E,.Fondo-V{background-color: #9acd32 !important;}
.Fondo-A-E,.Fondo-A{background-color: #ffff00 !important;}
.Fondo-1-Blank,.Fondo-A-1, .Fondo-R-1, .Fondo-V-1 {
    border-radius: 5px;    
    padding: .5rem !important;
    text-align: center;
    vertical-align: middle;
}
.Fondo-R-1{background-color:lightcoral !important ;    }
.Fondo-V-1{background-color: yellowgreen !important;    }
.Fondo-A-1{background-color: yellow !important;    }
.result-cell {
        margin-top: 0.1rem !important;
        display: flex;
        align-items: stretch;
}
.justified-flex {
    display: flex;
    justify-content: space-around;
}
.result-grid {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
