.clickable-end,.clickable-table {
    background-color: #4538d1 !important;
    color: white !important;
    text-align: center;
    cursor: pointer;
}
.clickable-end-paused {
    background-color: #Dad8f6 !important;
    color: black !important
}
.clickable-end, .clickable-end-paused {
    border-radius: 0px 10px 10px 0px;    
    width: 2.5vw !important;
    vertical-align: middle !important;
}
.clickable {
    cursor: pointer;
    color: inherit !important;
}
/* estilos para los botones  */
.App-button,.App-button button  {
    border-radius: 15px;
    color: white;
    background-color: #3868f8 !important;
    /* border-color: #4538d1 !important;    */ 
    width: auto !important;
}
.App-button-alt {
    background-color: lightgray !important;
    color: black !important;
    border: lightgray solid thin !important;    
    width: auto !important;
}
.App-button-dis {
    background-color: gray !important;
    color: white !important;
    border: lightgray solid thin !important;    
    width: auto !important;
}
.min-App-button {
    background-color: #4538d1 !important;
    border-color: #4538d1 !important;      
    width: fit-content !important; 
}
.min-App-button-alt {
    background-color: lightgray !important;
    color: black !important;
    border: lightgray solid thin !important;        
    width: fit-content !important;
}
.min-App-button-dis {
    background-color: gray !important;
    color: white !important;
    border: lightgray solid thin !important;        
    width: fit-content !important;
}
/* graficos de gestion diaria */
.content-container {
    width: 100%;
    max-width: 80vw;
    margin-top: 1%;
    background-color: #FFF;
}
.content-cont{
    width: 95%;
    /* max-width: 80vw; */
}
.content_dos{
    /* background: #123c58; */
    width: 90%;
}
.ancho_in {
    width: 400px !important; /* Se adapta al contenedor */
    max-width: 400px !important; /* Máximo ancho */
    padding: 7px 16px !important;
    font-size: 16px !important;
    color: #898989 !important;
    background-color: #fff !important;
    border: 1px solid #bbbbbb !important; /* Borde con color elegante */
    border-radius: 20px !important; /* Esquinas redondeadas */
    outline: none !important;
    transition: all 0.3s ease-in-out !important;
    appearance: none !important; /* Oculta la flecha por defecto en algunos navegadores */
    position: relative !important;
}
/* Para dar la apariencia de un input */
.ancho_in:focus {
    border-color: #000000 !important; /* Color más intenso al enfocar */
    box-shadow: 0px 0px 8px #8d8d8d80 !important;
}

/* Cambia el fondo cuando está activo o pasa el mouse */
.ancho_in:active,
.ancho_in:hover {
    background-color: #ededed !important;
}

/* Personalizar las opciones dentro del select */
.ancho_in option {
    background: #fff !important;
    color: #123c58 !important;
    padding: 10px !important;
    font-size: 14px !important;
}

/* Cambia el fondo de las opciones al pasar el mouse */
.ancho_in option:hover {
    background: #e6f0f7 !important;
}

.collumn-altos{
    /* background: green; */
    /* height: 500px; */
    width: 95%;
}
.collumn-alt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* diseños de tablas para mostrar informmacion */
.content-body-alt{
    border-radius: 10px;
    border: 1px solid #030303;
    padding: 12px;
    /* width: 1200px; */
}

.App-Table {
    /* border: 2px solid #000000; */
    border-radius: 10px;
    /* width: 1000px !important; */
    display: inherit !important;
    border-collapse: inherit;
    overflow-x: auto;

}
.table-th,.table-th-store {
    max-width: 12rem;
    width: 12rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    /* border-radius:10px ; */
    text-align: center ;
    padding: 0.2rem !important;
    vertical-align: middle;
    color: #ffffff;
    background-color: #123c58 !important;
}
.centered-td {
    display: flex;
    justify-content: center;
}
.table-td {
    width: 6rem !important;
    text-overflow: ellipsis;
    overflow-x: hidden;   
    border-collapse: collapse;
    border-right: thin solid #e6e6e6;
}
.extended {
    white-space: nowrap;
    vertical-align: middle;
    padding: 1rem 3rem !important;
    max-width: fit-content !important;
    width: 12rem !important;
    overflow: visible !important;
}
.extended-1 {
    white-space: nowrap;
    vertical-align: middle;
    padding: 1rem 3rem !important;    
    width: 12rem !important;
    overflow: visible !important;
}
.table-flex {
    display: flex;
    justify-content: space-evenly;
}
.tr-fixed {
    display: inline-flex;
    width: 25vw;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
}
.table-data {
    padding: .44rem !important;
    display: flex;
}
.blue-text {
    color: #4538d1 !important;
    text-decoration: underline;
    cursor: pointer;
}
.image-modal {
    width: 100% !important;
    height: 38vw !important;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}
.flex-between {
    display: flex;
    justify-content: space-between;
}

.cont_base{
    height: 350px;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 0px 10px 5px #d1d1d1;
}