
/* modificacion temporal */
.side-container{
    width: 290px;
    min-width: 290px;
    max-width: 290px;
    /* background-color: #0c4a6e; */
    background: linear-gradient(35deg, #000000, #0c4a6e);
    padding:40px 20px;
    /* border: 1px solid black; */
    
}

/* contenedor de la info que se busca*/
.main-container {
    padding: 1rem;
    /* border-radius: 10px; */
    background-color: #FFF;
    width: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
}

/* contendor de la barra lateral, el color y el padding */
.over-container {
    display: flex;
    width: 100%;
    /* margin-top: 1%; */
    /* padding: 1rem !important; */
    /* border: 1px solid black;  */
    /* background-color: #dad8f6; color para saver los bordes o espaciados*/
}

.barra-color{
    color:#9f9f9f !important;
    font-size: 16px !important;
    margin: 3px 0;
}
/* color de letra de la barra y el encabezado  */
/* .nav-link {
    color: #a8a8a8;
    font-size: 18px;
} */
/* estilos para el hover al pasar en mouse */
.nav-link:hover {
    background-color: #ddd !important;    
    color: #494949 !important;    
    font-weight: bold;    
    transform: translateX(.2rem);
    transition: transform .5s; 
}

/* color de la letra de la barra cuando activamos el boton */
.active {
    color: #ffffff !important;
    background-color: #0c4a6e !important;
}

/* estilos de acordeon del Sidebard */

/* Eliminar bordes del acordeón */
.accordion, .accordion-item, .accordion-header, .accordion-button, .accordion-body {
    border: none !important;
    box-shadow: none !important;
}

/* Eliminar bordes cuando el botón está expandido */
.accordion-button {
    border: none !important;
    box-shadow: none !important;
    background-color: #0c4a6e !important;
    color: white !important;
}

/* Quitar borde de la cabecera cuando está activa */
.accordion-button:not(.collapsed) {
    color: #ffffff !important;
    background-color: #0c4a6e !important;
    border: none !important;
    box-shadow: none !important;
}

/* Quitar borde cuando el botón es clickeado */
.accordion-button:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

/* Quitar bordes internos */
.accordion-item {
    border: none !important;
    background-color: transparent !important;
}












/* aun por descubrir para que son estos estilos */
/* ==============Cambios que estan en la parte de encuestas modificacion de lugar ================ */

/* falta saber que es lo que hace */
.banner {
    font-size: x-large;
    font-weight: 500;
    margin-left: 1rem;
}

.Banner {
    height: 40vh !important;
    width: inherit;
    background-color: #FFF;
    border-radius: 10px;
    padding: 0rem 1rem 1rem 1rem;
    box-shadow: 0px 0px 10px 1px #dad8f6;
}
.sub-banner {
    height: 25vh !important;
}
.sub-banner-min {
    height: 15vh !important;
}
.Banner-alt {
    height: 40vh !important;
    width: inherit;
    background-color: #FFF;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: inset 0px 0px 10px 0px #dad8f6;
}
.offcanvas.offcanvas-start {
    max-width: 80vw !important;
}



/* estilos para las barras de los graficos */
/* ========================================== */


.Echart {
    height: 30vh;
    width: 30vw;
}
.Echart-2 {
    height: 30vh;
    width: 25vw;
}
.Echart-half {
    height: 35vh;
    width: 50vw;
}
.Echart-half-high {
    height: 41vh;
    width: 50vw;
}
.Echart-smol {
    height: 35vh;
    width: 20vw;
}
.Echart-2-3 {
    height: 35vh;
    width: 12vw;
}
.Echart-wide {
    height: 35vh;
    width: 70vw;
}
/* colores para los contenedores de los graficos  */
.bkg-white {
    background-color: white !important;
}
.bkg-cyan {
    background-color: #ccf5f8 !important;
}
.bkg-lilly {
    background-color: #e9e7ff !important;
}
.bkg-pink {
    background-color: #fee7ff !important;
}
.nowrap {
    white-space: nowrap !important;
}