.App {
  text-align: center;
  font-family: 'Encode Sans Semi Expanded' !important;
}

body {
  overflow-x: hidden;
  font-size: 0.8rem !important;  
}

td {
  padding: .2rem .5rem !important;
  text-align: left ;
}

.fixed {
  position: sticky !important;
}

.black>.dropdown-container {
  border: 1px solid black !important;
}
.filter {
  border: 1px solid;
  border-radius: 5px;
}
.loader {    
  position: absolute;
  top: 20vh;
  z-index: 15;
  background-color: rgba(245, 245, 245, 0.8);
  min-width: 76%;
  min-height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}