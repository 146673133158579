/* diseños del logo y la opcoio de usuario y salir y el logo de empresa seleccionada */
.header-banner {
    background-color: #FFF;
    color: whitesmoke;
    display: flex; 
}

/* tamaño del logo sin necesidad del !important*/
.baner-log{
    display: flex;
    align-items: center;
    width: 290px;
    min-width: 290px;
    max-width: 290px;
    /* background: linear-gradient(10deg, #0f5f8d, #ffffff); */
    padding: 0 0 0 40px;
}
.banner-logo {
    width: 22vh !important;
    max-width: 22vh !important;
}

@media (max-width: 768px) {  /* Ajusta el tamaño según sea necesario */
    .baner-log {
        width: 200px; /* Reduce el tamaño para móviles */
        min-width: unset; /* Opcional, para evitar restricciones */
        max-width: 200px;
    }
}

/* posicion de la barra de ini,enc, rep, cde */
.Tabs {
    position: absolute /* !important */;
    bottom: 0vh /* !important */;
    border-bottom: none /* !important */;/* cambio la posicion sin !important */
    color: #4538D1;
}
/* fata descubrir que es lo que hace */
/* diseño de los contenedores de la barra del header, aun no se porque no funciona */
.tap, .tap-2 {
    background-color: #4538D1;
    width: 10vw !important;
    max-width: 60px !important;
    cursor: pointer;    
    top: 10vh;
    z-index: 3;    
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: x-large;
    aspect-ratio: 1/1;
}
/* diseño de los bordes del contenedor de los botones */
.tap {
    right: 0%;    
    border-radius: 10px 0px 0px 10px;
}
.tap-2 {    
    left: -1%;
    border-radius: 0px 10px 10px 0px;
}
/* avatar para saber en que perfin estamos */
.avatar {
    background-color: #aaa;
    border-radius: 50%;
    width: 3vw;
    height: 3vw;
    aspect-ratio: 1/1;
}

/* diseño para salir, info, perfil */
.user-tags {
    display: flex;
    align-items: center;
}

/* color de hover al pasar el mouse de los botones  */
.clickable-header {    
    cursor: pointer;
    color: #A6a6a6;
}
/* no se save aun que es lo que hace */
.disabled {
    background-color: #DDD !important;
    color: #A6a6a6 !important;
}
/* opcional, puedo cambiar el color de las letras de la barra del header con un important*/
/* ================================================================ */
.fix {
    display: flex; 
    align-items:center;
    color: #0c4a6e !important;
    font-size: 16px !important;

}
/* ================================================================ */

/* estilos para el icono de config boton salir */
.dropdown-clickable {
    background-color: transparent !important;
    border: none !important;
    padding: revert !important;
}
/* hace invisible a la flechita del icono de config*/
.dropdown-toggle::after {
    display: none !important;
}
/* diseño del logo para el avatar */
.header-logo {
    max-height: 100%;
    vertical-align: top;
    aspect-ratio: inherit !important;
    border-radius: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
/* no se save lo que hace aun */
.report-logo {
    aspect-ratio: 1/1;
    border-radius: 50%;
    vertical-align: inherit;
    height: 10vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
/* media para hacer invisible el icono cangreburger en compu y laptop */
@media only screen and (min-width: 1110px) {
    .hidden-lg {
        display: none;
    }
}