.expanded {
    min-width: 20rem;
}
.Sticky-title {    
        position: sticky;
        top: 0px;
}
.table-responsive { 
    max-height: 60vh;
    /* set table with responsive class, this here sets the max */
}
.align-center {
    align-content: center !important;
}