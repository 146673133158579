.Main{
    display: flex;
    background-color: #dad8f6;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 55vw !important;
    align-items: center;
    justify-content: center;    
}
/* contenedor para el formulario */
.form-login {
    max-width: 27vw;
    padding: 1.5rem;
    margin: 0rem;
    margin-top: 4rem;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 20px 2px #ECEBF9;
}
/* posicion del login  */
.login-box{
    position: absolute;
    right: 55vw;
    z-index: 4;
}
/* diseño de los form */
.form-field {
    margin: 0.3em;
    border: none !important;
    border-bottom: thin solid black !important;
}
.button-holder {
    display: flex;
    padding: 0px 3rem;
    justify-content: space-evenly;
}
.Label{
    font-weight: bold;
    font-size: small;
}
.Button {
    background-color: #4538D1 !important;
    border-radius: 15px !important;
}
.Button-alt {
    background-color: #F00 !important;
    border: thin solid #E5E5E5 !important ;    
    border-radius: 15px !important;
}
.Title {
font-weight: bolder;
color:#000
}
.option {
    justify-self: center;
    color: #A6A6A6;
    font-size: medium;
    display: flex;
    justify-content: center;
}
.option-alt {
    font-size:small;
}
.option-alt:hover {    
    color: #4538D1;
    cursor: pointer;
}

.Add-on {
    background-color: #4538D1 !important;
    color: #E5E5E5 !important;
}

.chart-icon {
    border: none !important;
    background-color: #FFFFFF !important;
}

.form-showcase {
    display: flex;
    justify-content: center;
}
/* cambios no reflejados aun */
.over {
    top: 15vh;
    right: 5vw;
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 60vh;
    justify-content: space-around;
}

.speedGraph {
    position: absolute;
    top: 30vh;
}

.style-group {
    width: 18vw !important;
    margin: 0px 0px 0px 10vw;
}

.style-group-alt {
    width: 23vw !important;
    margin: 0vw 0px 0px 0px;
}

/* tamaño del logo de inicio de session  */
/* sin el important no funciona XD */
.logo {
    width: 18vh !important;
}
/* algo hara xd no se ve nada aun */

@media only screen and (max-width: 1110px) {
    .front-table {
        max-width: unset !important;
    }
    .hidden-sm {
        display: none !important;
    }
    .main-container {
        width: 100% !important;
    }
    .sm-center {
        justify-content: center;
    }
    .inicio-search,.select-search {
        width: 38vw !important;
    }
    .collumn-alt {
        align-items: center !important;
    }
    .client-view{
        flex-direction: column;
    }
    .Echart {
        height: 35vh !important;
        width: 90vw !important;
    }
    .Echart-half {
        height: 35vh !important;
        width: 90vw !important;
    }
    .Echart-2-3 {
        width: 22vw !important;
    }
    .avatar {
        height: 7vw;
        width: 7vw;
    }
    .clickable-header {     
        font-size: x-large;
    }
}
@media only screen and (max-width: 1110px) {
    .hidden-md{display: none !important;}
    .login-box {
        right: 0px;
        position: fixed;
        left: 0px;
        padding: 0rem !important;        
    }
    .multi-selecto {
        max-width: 25vw !important;
    }
    .image-active{
        width: 15vh !important;
    }
    .End {
        padding: 20vh 0px !important;
    }
    .short-text {
        max-width: 80vw !important;
    }
    .justify-center { 
        justify-content: center !important;
    }
    .footer {
        width: 100vw !important;
    }
    .form-login{
        width: max-content;
        min-width: fit-content !important;
        max-width: unset;
    }
    .login-banner{
        display: none !important;
    }
    .option-container-modal {
        width: 24vh !important;
    }
}
@media only screen and (max-width: 603px) {
    .inicio-search,.select-search {
        width: 28vh !important;
    }
    .multi-selecto {
        max-width: 25vw !important;
    }
}
/* hace que el baner o imagen se haga mas pequeño */
.login-banner {
    position: fixed;
    width: 100vw;
    z-index: 2;
}