.outer-body {
    margin: 2rem;
    padding: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: thin solid black;
    border-radius: 15px;
    background-color: #dad8f6 !important;
}
.justify-center {
    justify-content: center;
}

.max-height-50 {
    max-height: 50vh !important;
}