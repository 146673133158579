.sticky-col {
    position: -webkit-sticky;
    position: sticky;
    font-weight: bold;
  }  
.first-col {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    left: 0px;
    color: white !important;
    font-weight: bold;
  }
  .first-col-content {
    background-color: white !important;
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    left: 0px;
  }
  .second-col { 
    left: 6rem;
   }
   .third-col {
    left: 26rem;
   }
   .fourth-col {
     left: 30rem;
   }
   .second-col-content { 
     background-color: white !important;
     left: 6rem;
    }
   .third-col-content{
    background-color: white !important;
    left: 26rem;
   }
   .fourth-col-content {
    left: 30rem;
    background-color: white !important;
   }

  .p-1-alt { 
    padding: 0.2rem !important;
  }