.inner-list {
    box-shadow: inset 0px 0px 10px 0px #dad8f6;
    border-radius: 10px;
    padding: 1rem;
}

.boxed {
    border: solid #Dad8f6 1px !important;
    border-radius: 0px 0px 5px 5px;
}
.top-box {
    background-color: #Dad8f6 !important;
    border-radius: 5px 5px 0px 0px;
}

@media only screen and (min-width: 790px) {
    .rmsc, .form-select {
        max-width: 66.5vh !important;
    }
}