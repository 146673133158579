/* diseños de la parte del footer */
.footer-container {
    position: relative;
    bottom: 0px;
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #030303 !important;
   
} 
/* color tamaño y posicion del pie de pagina XD */
.footer {
    color: #ffffff;
    margin: 0px !important;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    font-size: medium;
}
/* no refleja aun  */
.footer {
    width: 100vw;
    display: flex;
    position: relative;
}